export const SENTRY_DSN =
  'https://53ff996300e94e7983242fc53bc63c5f@sentry.io/1360936';

export const PG_APP_DEFINITION_ID = '14271d6f-ba62-d045-549b-ab972ae1f70e';
export const GALLERY_WIDGET_ID = '142bb34d-3439-576a-7118-683e690a1e0d';
export const MULTISHARE_WIDGET_ID = '1491fc25-fcd2-9c08-c74f-67b98b69ac4a';
export const FULLSCREEN_PAGE_ID = '144f04b9-aab4-fde7-179b-780c11da4f46';

export const PG_TEST_APP_DEFINITION_ID = 'f27276e7-0858-460c-a450-4ba27cfe6eea';
export const PG_TEST_GALLERY_WIDGET_ID = 'c89921a0-ef16-45a8-931d-e7d3e5a02cdf';
