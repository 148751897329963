import ProGalleryStore from './ProGalleryStore';
import { experimentsWrapper } from '@wix/photography-client-lib/dist/src/sdk/experimentsWrapper';
import translationUtils from '@wix/photography-client-lib/dist/src/utils/translationUtils';
import galleryWixCodeApi from './wixCodeApi';
import SentryReporter from './SentryReporter';
import { utils } from '../utils';
import { PG_TEST_GALLERY_WIDGET_ID, GALLERY_WIDGET_ID } from '../constants';
import { serverItemsToProGallery } from '@wix/pro-gallery-items-formatter/dist/src/server';
import directFullscreenHelper from '../helpers/directFullscreenHelper';

let context;
const sentryReporter = new SentryReporter();
export function initAppForPage(
  initAppParam,
  _platformUtilitiesApi,
  scopedGlobalSdkApis,
  platformServices,
) {
  context = {
    ...initAppParam,
    platformServices,
    scopedGlobalSdkApis, // delete when 'specs.pro-gallery.appSettings' is merged - no need to get experiments
  };
  sentryReporter.init(initAppParam, scopedGlobalSdkApis, platformServices);

  try {
    const translationsPromise = ProGalleryStore.getTranslations();
    const experimentsPromise = ProGalleryStore.getExperiments(
      scopedGlobalSdkApis,
    );

    Promise.all([experimentsPromise, translationsPromise])
      .catch(e => {
        sentryReporter.report(e);
        console.error('Waiting for promises failed', e);
      })
      .then(([experimentsRaw, translationsRaw]) => {
        translationUtils.setTranslations(translationsRaw);
        experimentsWrapper.setExperiments(experimentsRaw);
      });
  } catch (e) {
    sentryReporter.report(e);
    console.error('Getting page init data failed', e);
  }
}

function controller(controllerData) {
  const {
    appParams: { instanceId, instance },
    config: { externalId },
    compId,
    // $w,
    setProps,
    wixCodeApi,
    warmupData,
    type,
  } = controllerData;

  if (![GALLERY_WIDGET_ID, PG_TEST_GALLERY_WIDGET_ID].includes(type)) {
    return {
      pageReady: () => {},
      exports: () => {},
    };
  }
  const { msid, platformServices, scopedGlobalSdkApis } = context; // delete scopedGlobalSdkApis if we don't get the experiments in the proGalleryStore
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  const proGalleryStore = new ProGalleryStore(
    {
      externalId,
      compId,
      instanceId,
      instance,
      msid,
    },
    wixCodeApi,
    platformServices,
    scopedGlobalSdkApis, // delete when appSettings experiment is merge
  );
  const queryParams = wixCodeApi.location.query;

  const getMoreItems = from => {
    if (proGalleryStore.itemsSrc !== 'wixCode') {
      proGalleryStore.getItemsFromServer(from).then(items => {
        proGalleryStore.addItems(items, from);
        setProps({
          items: proGalleryStore.getItems(),
        });
      });
    }
  };

  const getAndSetDirectFullscreenItem = itemId => {
    if (proGalleryStore.itemsSrc !== 'wixCode') {
      proGalleryStore.getItemByIdFromServer(itemId).then(res => {
        if (typeof res.item === 'object') {
          const formattedItem = serverItemsToProGallery([res.item]);
          setProps({
            directFullscreenItem: formattedItem[0],
          });
        } else {
          console.warn('directFullscreen item returned empty from server', res);
        }
      });
    }
  };

  if (warmupData) {
    proGalleryStore.setItems(warmupData.items);
    proGalleryStore.totalItemsCount = warmupData.totalItemsCount;
    experimentsWrapper.setExperiments(warmupData.experiments);
  }

  const { baseUrls } = context;

  const biContext = {
    _msid: controllerData.platformAPIs.bi.metaSiteId,
    _appId: controllerData.appParams.appDefinitionId,
    _instanceId: controllerData.appParams.instanceId,
    _siteOwnerId: controllerData.platformAPIs.bi.ownerId,
    _siteMemberId: controllerData.platformAPIs.bi.siteMemberId || '',
    _visitorId: controllerData.platformAPIs.bi.visitorId,
    _viewMode: wixCodeApi.window.viewMode,
  };

  const onItemClicked = (itemData, itemClickStyleParam) => {
    //gallery item clicked
    galleryWixCodeApi.onItemClicked(itemData);
    proGalleryStore.onItemClicked(itemData, itemClickStyleParam);
  };
  const onLinkNavigation = itemData => {
    //fullscreen link navigation
    proGalleryStore.navigateToLink(itemData);
  };
  const setMetaTags = item => {
    //Setting metaTags for SEO fullscreen navigation
    //console.log('logged in the viewerScript', item);
    //wixCodeApi.seo.renderSEOTags({});
  };
  const onCurrentItemChanged = itemData => {
    galleryWixCodeApi.onCurrentItemChanged(itemData);
  };

  const setHeight = height => {
    setProps({
      dimensions: {
        height,
      },
    });
  };

  return {
    //page ready return a promise, which its resolved value will be warmup data
    pageReady: () => {
      try {
        // const { reportTrace } = platformServices;
        // reportTrace({
        //   actionName: 'proGallery/ready',
        //   tracePosition: 'before', //optional before or after
        //   actionDurationMs: 330, //the time (according to our measurements) the action took
        // });

        const directFullscreenPromise = directFullscreenHelper.getDirectFullscreenPromise(
          compId,
          queryParams,
          proGalleryStore,
          isSSR,
        );
        const itemsPromise = proGalleryStore.loadInitialItems(isSSR);
        const viewportPromise =
          (isSSR && wixCodeApi.window.getComponentViewportState(compId)) ||
          Promise.resolve({ in: true });

        const renderGallery = viewPortState => {
          if (viewPortState && !viewPortState.in) {
            //render none
            setProps({
              notInView: true,
            });
            proGalleryStore.reportAppLoaded();
            return null;
          }

          return Promise.all([itemsPromise, directFullscreenPromise])
            .catch(e => {
              sentryReporter.report(e);
              console.error('Waiting for promises failed', e);
            })
            .then(() => {
              if (utils.isVerbose()) {
                console.log('query: ', JSON.stringify(queryParams));
              }
              const baseUrl = ProGalleryStore.getBaseUrl(wixCodeApi);
              const siteUrl = ProGalleryStore.getSiteUrl(wixCodeApi);
              const fullscreenUrl = siteUrl + '/fullscreen-page';
              const isInSEO =
                typeof wixCodeApi.seo.isInSEO === 'function' &&
                !!wixCodeApi.seo.isInSEO();
              const viewMode = wixCodeApi.window.viewMode;
              const pageUrl = ProGalleryStore.getPageUrl(wixCodeApi);
              setProps({
                baseUrl,
                siteUrl,
                fullscreenUrl,
                pageUrl,
                viewMode,
                biContext,
                scrollTo: wixCodeApi.window.scrollTo,
                onItemClicked,
                onLinkNavigation,
                setMetaTags,
                onCurrentItemChanged,
                queryParams,
                isInSEO,
                notInView: false,
                setHeight,
                setItems: proGalleryStore.setItems,
                cssBaseUrl: baseUrls.santaWrapperBaseUrl,
                directFullscreenItem: proGalleryStore.getDirectFullscreenItem(),
                directFullscreenStory: proGalleryStore.getDirectFullscreenStory(),
                mockTrueProp: true,
                mockFalseProp: false,
                mockUndefinedProp: undefined,
                mockStringProp: '3',
                mockItemProp: {
                  itemId: 'ff259001-45e1-4be9-a21a-396a0cfece7a',
                  mediaUrl: '89ea07a19c3d415e99a8a8a3c0ab1de8.jpg',
                  metaData: {
                    description: 'asdfsadfsadfsadf',
                    fileName: 'Plants on the Window',
                    height: 1280,
                    isDemo: true,
                    link: { type: 'none', target: '_blank' },
                    name: '89ea07a19c3d415e99a8a8a3c0ab1de8.jpg',
                    sourceName: 'private',
                    tags: [],
                    title: 'Page - Normal Gallery, this tab',
                    width: 1920,
                  },
                  orderIndex: 1541586965.444,
                  secure: false,
                },
                items: proGalleryStore.getItems(),
                totalItemsCount: proGalleryStore.totalItemsCount,
                getMoreItems: getMoreItems,
                manualStyleParams: galleryWixCodeApi.manualStyleParams,
                gallerySettings: proGalleryStore.settings,
                galleryId: proGalleryStore.galleryId,
                dateCreated: proGalleryStore.dateCreated,
                reportAppLoaded: proGalleryStore.reportAppLoaded,
                translations: translationUtils.translations,
                experiments: experimentsWrapper.experiments,
                instanceId,
                onNavigate: url => {
                  wixCodeApi.location.to(url);
                },
              });

              if (isSSR) {
                proGalleryStore.reportAppLoaded();
              }

              return {
                //return value here 'returns' as warmup data
                items: proGalleryStore.getItems(),
                totalItemsCount: proGalleryStore.totalItemsCount,
                experiments: experimentsWrapper.experiments,
              };
            });
        };

        return viewportPromise.then(renderGallery).catch(error => {
          console.error(error);
          sentryReporter.report({
            errorMessage: 'viewportPromise rejected!',
            error,
          });
          proGalleryStore.reportAppLoaded();
          // renderGallery({ in: true });
        });
      } catch (e) {
        console.error(e);
        sentryReporter.report(e);
      }
    },
    updateConfig: ($w, updatedConfig) => {
      try {
        if (utils.isVerbose()) {
          console.log('got update config!');
        }
      } catch (e) {
        sentryReporter.report(e);
      }
    },
    exports: () => {
      try {
        const setNewItems = items => {
          const directFullscreenItem = directFullscreenHelper.getDirectFullscreenItemFromItemsList(
            queryParams,
            items,
          );
          directFullscreenItem &&
            proGalleryStore.setDirectFullscreenItem(directFullscreenItem);
          setProps({
            directFullscreenItem,
            wixCodeItems: items
              .filter(item => !!item.mediaUrl)
              .map(item => {
                return {
                  ...item,
                  metaData: JSON.stringify(item.metaData),
                };
              }),
          });
        };
        const setNewStyleParams = styleParams => {
          setProps({
            manualStyleParams: styleParams,
          });
        };
        return galleryWixCodeApi.generateApi({
          proGalleryStore,
          setNewItems,
          setNewStyleParams,
        });
      } catch (e) {
        sentryReporter.report(e);
      }
    },
  };
}

export function createControllers(controllerConfigs) {
  try {
    return controllerConfigs.map(function(config) {
      return Promise.resolve(controller(config));
    });
  } catch (e) {
    sentryReporter.report(e);
  }
}

export const wrappedFunctions = {
  initAppForPage: initAppForPage,
  createControllers: createControllers,
};
